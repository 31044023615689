define("discourse/plugins/chat/discourse/models/chat-message-reaction", ["exports", "@glimmer/tracking", "@ember-compat/tracked-built-ins", "discourse/models/user"], function (_exports, _tracking, _trackedBuiltIns, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.REACTIONS = void 0;
  const REACTIONS = _exports.REACTIONS = {
    add: "add",
    remove: "remove"
  };
  class ChatMessageReaction {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatMessageReaction(args);
    }
    static #_ = dt7948.g(this.prototype, "count", [_tracking.tracked], function () {
      return 0;
    });
    #count = (dt7948.i(this, "count"), void 0);
    static #_2 = dt7948.g(this.prototype, "reacted", [_tracking.tracked], function () {
      return false;
    });
    #reacted = (dt7948.i(this, "reacted"), void 0);
    static #_3 = dt7948.g(this.prototype, "users", [_tracking.tracked], function () {
      return [];
    });
    #users = (dt7948.i(this, "users"), void 0);
    static #_4 = dt7948.g(this.prototype, "emoji", [_tracking.tracked]);
    #emoji = (dt7948.i(this, "emoji"), void 0);
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.count = args.count;
      this.emoji = args.emoji;
      this.users = this.#initUsersModels(args.users);
      this.reacted = args.reacted;
    }
    #initUsersModels() {
      let users = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return new _trackedBuiltIns.TrackedArray(users.map(user => {
        if (user instanceof _user.default) {
          return user;
        }
        return _user.default.create(user);
      }));
    }
  }
  _exports.default = ChatMessageReaction;
});