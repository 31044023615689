define("discourse/plugins/chat/discourse/components/chat/selection-manager", ["exports", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/move-message-to-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _application, _object, _service, _truthHelpers, _dButton, _ajaxError, _utilities, _environment, _decorators, _discourseI18n, _moveMessageToChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatSelectionManager extends _component.default {
    static #_ = dt7948.g(this.prototype, "topicComposer", [(0, _service.service)("composer")]);
    #topicComposer = (dt7948.i(this, "topicComposer"), void 0);
    static #_2 = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_3 = dt7948.g(this.prototype, "modal", [_service.service]);
    #modal = (dt7948.i(this, "modal"), void 0);
    static #_4 = dt7948.g(this.prototype, "site", [_service.service]);
    #site = (dt7948.i(this, "site"), void 0);
    static #_5 = dt7948.g(this.prototype, "toasts", [_service.service]);
    #toasts = (dt7948.i(this, "toasts"), void 0);
    static #_6 = dt7948.g(this.prototype, "api", [(0, _service.service)("chat-api")]);
    #api = (dt7948.i(this, "api"), void 0);
    get enableMove() {
      return this.args.enableMove ?? false;
    }
    get anyMessagesSelected() {
      return this.args.pane.selectedMessageIds.length > 0;
    }
    async generateQuote() {
      const {
        markdown: markdown1
      } = await this.api.generateQuote(this.args.pane.channel.id, this.args.pane.selectedMessageIds);
      return new Blob([markdown1], {
        type: "text/plain"
      });
    }
    static #_7 = dt7948.n(this.prototype, "generateQuote", [_decorators.bind]);
    openMoveMessageModal() {
      this.modal.show(_moveMessageToChannel.default, {
        model: {
          sourceChannel: this.args.pane.channel,
          selectedMessageIds: this.args.pane.selectedMessageIds
        }
      });
    }
    static #_8 = dt7948.n(this.prototype, "openMoveMessageModal", [_object.action]);
    async quoteMessages() {
      let quoteMarkdown1;
      try {
        const quoteMarkdownBlob1 = await this.generateQuote();
        quoteMarkdown1 = await quoteMarkdownBlob1.text();
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
      const openOpts1 = {};
      if (this.args.pane.channel.isCategoryChannel) {
        openOpts1.categoryId = this.args.pane.channel.chatableId;
      }
      if (this.site.mobileView) {
        // go to the relevant chatable (e.g. category) and open the
        // composer to insert text
        if (this.args.pane.channel.chatableUrl) {
          this.router.transitionTo(this.args.pane.channel.chatableUrl);
        }
        await this.topicComposer.focusComposer({
          fallbackToNewTopic: true,
          insertText: quoteMarkdown1,
          openOpts: openOpts1
        });
      } else {
        // open the composer and insert text, reply to the current
        // topic if there is one, use the active draft if there is one
        const container1 = (0, _application.getOwner)(this);
        const topic1 = container1.lookup("controller:topic");
        await this.topicComposer.focusComposer({
          fallbackToNewTopic: true,
          topic: topic1?.model,
          insertText: quoteMarkdown1,
          openOpts: openOpts1
        });
      }
    }
    static #_9 = dt7948.n(this.prototype, "quoteMessages", [_object.action]);
    async copyMessages() {
      try {
        if (!(0, _environment.isTesting)()) {
          // clipboard API throws errors in tests
          await (0, _utilities.clipboardCopyAsync)(this.generateQuote);
          this.toasts.success({
            duration: 3000,
            data: {
              message: _discourseI18n.default.t("chat.quote.copy_success")
            }
          });
        }
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_10 = dt7948.n(this.prototype, "copyMessages", [_object.action]);
    static #_11 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="chat-selection-management"
          data-last-copy-successful={{this.lastCopySuccessful}}
        >
          <div class="chat-selection-management__buttons">
            <DButton
              @icon="quote-left"
              @label="chat.selection.quote_selection"
              @disabled={{not this.anyMessagesSelected}}
              @action={{this.quoteMessages}}
              id="chat-quote-btn"
            />
    
            <DButton
              @icon="copy"
              @label="chat.selection.copy"
              @disabled={{not this.anyMessagesSelected}}
              @action={{this.copyMessages}}
              id="chat-copy-btn"
            />
    
            {{#if this.enableMove}}
              <DButton
                @icon="sign-out-alt"
                @label="chat.selection.move_selection_to_channel"
                @disabled={{not this.anyMessagesSelected}}
                @action={{this.openMoveMessageModal}}
                id="chat-move-to-channel-btn"
              />
            {{/if}}
    
            <DButton
              @icon="times"
              @label="chat.selection.cancel"
              @action={{@pane.cancelSelecting}}
              id="chat-cancel-selection-btn"
              class="btn-secondary cancel-btn"
            />
          </div>
        </div>
      
    */
    {
      "id": "pwb+T3M4",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-selection-management\"],[15,\"data-last-copy-successful\",[30,0,[\"lastCopySuccessful\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-selection-management__buttons\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,1,\"chat-quote-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"quote-left\",\"chat.selection.quote_selection\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"quoteMessages\"]]]],null],[1,\"\\n\\n        \"],[8,[32,0],[[24,1,\"chat-copy-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"copy\",\"chat.selection.copy\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"copyMessages\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"enableMove\"]],[[[1,\"          \"],[8,[32,0],[[24,1,\"chat-move-to-channel-btn\"]],[[\"@icon\",\"@label\",\"@disabled\",\"@action\"],[\"sign-out-alt\",\"chat.selection.move_selection_to_channel\",[28,[32,1],[[30,0,[\"anyMessagesSelected\"]]],null],[30,0,[\"openMoveMessageModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,0],[[24,1,\"chat-cancel-selection-btn\"],[24,0,\"btn-secondary cancel-btn\"]],[[\"@icon\",\"@label\",\"@action\"],[\"times\",\"chat.selection.cancel\",[30,1,[\"cancelSelecting\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@pane\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/selection-manager.js",
      "scope": () => [_dButton.default, _truthHelpers.not],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatSelectionManager;
});