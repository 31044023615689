define("discourse/plugins/chat/discourse/components/chat/message-creator/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/plugins/chat/discourse/components/chat/message-creator/add-members", "discourse/plugins/chat/discourse/components/chat/message-creator/constants", "discourse/plugins/chat/discourse/components/chat/message-creator/new-group", "discourse/plugins/chat/discourse/components/chat/message-creator/search", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _addMembers, _constants, _newGroup, _search, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageCreator extends _component.default {
    static #_ = dt7948.g(this.prototype, "mode", [_tracking.tracked], function () {
      return _constants.MODES.search;
    });
    #mode = (dt7948.i(this, "mode"), void 0);
    static #_2 = dt7948.g(this.prototype, "members", [_tracking.tracked], function () {
      return [];
    });
    #members = (dt7948.i(this, "members"), void 0);
    get componentForMode() {
      switch (this.args.mode ?? this.mode) {
        case _constants.MODES.search:
          return _search.default;
        case _constants.MODES.new_group:
          return _newGroup.default;
        case _constants.MODES.add_members:
          return _addMembers.default;
      }
    }
    changeMode(mode1) {
      let members1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      this.mode = mode1;
      this.changeMembers(members1);
    }
    static #_3 = dt7948.n(this.prototype, "changeMode", [_object.action]);
    changeMembers(members1) {
      this.members = members1;
    }
    static #_4 = dt7948.n(this.prototype, "changeMembers", [_object.action]);
    cancelAction() {
      return this.args.onCancel?.() || this.changeMode(_constants.MODES.search);
    }
    static #_5 = dt7948.n(this.prototype, "cancelAction", [_object.action]);
    static #_6 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-creator-container">
          <div class="chat-message-creator">
            <this.componentForMode
              @channel={{@channel}}
              @onChangeMode={{this.changeMode}}
              @onChangeMembers={{this.changeMembers}}
              @close={{@onClose}}
              @cancel={{this.cancelAction}}
              @members={{this.members}}
            />
          </div>
        </div>
      
    */
    {
      "id": "KOmH179J",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator\"],[12],[1,\"\\n        \"],[8,[30,0,[\"componentForMode\"]],null,[[\"@channel\",\"@onChangeMode\",\"@onChangeMembers\",\"@close\",\"@cancel\",\"@members\"],[[30,1],[30,0,[\"changeMode\"]],[30,0,[\"changeMembers\"]],[30,2],[30,0,[\"cancelAction\"]],[30,0,[\"members\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@onClose\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/index.js",
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatMessageCreator;
});