define("discourse/plugins/chat/discourse/components/chat/admin/export-messages", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajaxError, _i18n, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatAdminExportMessages extends _component.default {
    static #_ = dt7948.g(this.prototype, "chatAdminApi", [_service.service]);
    #chatAdminApi = (dt7948.i(this, "chatAdminApi"), void 0);
    static #_2 = dt7948.g(this.prototype, "dialog", [_service.service]);
    #dialog = (dt7948.i(this, "dialog"), void 0);
    async exportMessages() {
      try {
        await this.chatAdminApi.exportMessages();
        this.dialog.alert(_discourseI18n.default.t("chat.admin.export_messages.export_has_started"));
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_3 = dt7948.n(this.prototype, "exportMessages", [_object.action]);
    static #_4 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="admin-section">
          <h3>{{i18n "chat.admin.export_messages.title"}}</h3>
          <p>{{i18n "chat.admin.export_messages.description"}}</p>
          <DButton
            @label="chat.admin.export_messages.create_export"
            @title="chat.admin.export_messages.create_export"
            @action={{this.exportMessages}}
            class="btn-primary"
          />
        </section>
      
    */
    {
      "id": "wNhgVu+r",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"admin-section\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"chat.admin.export_messages.title\"],null]],[13],[1,\"\\n      \"],[10,2],[12],[1,[28,[32,0],[\"chat.admin.export_messages.description\"],null]],[13],[1,\"\\n      \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@label\",\"@title\",\"@action\"],[\"chat.admin.export_messages.create_export\",\"chat.admin.export_messages.create_export\",[30,0,[\"exportMessages\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/admin/export-messages.js",
      "scope": () => [_i18n.default, _dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatAdminExportMessages;
});