define("discourse/plugins/chat/discourse/lib/collection", ["exports", "@glimmer/tracking", "rsvp", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _tracking, _rsvp, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Handles a paginated API response.
   */
  class Collection {
    static #_ = dt7948.g(this.prototype, "items", [_tracking.tracked], function () {
      return [];
    });
    #items = (dt7948.i(this, "items"), void 0);
    static #_2 = dt7948.g(this.prototype, "meta", [_tracking.tracked], function () {
      return {};
    });
    #meta = (dt7948.i(this, "meta"), void 0);
    static #_3 = dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    });
    #loading = (dt7948.i(this, "loading"), void 0);
    static #_4 = dt7948.g(this.prototype, "fetchedOnce", [_tracking.tracked], function () {
      return false;
    });
    #fetchedOnce = (dt7948.i(this, "fetchedOnce"), void 0);
    constructor(resourceURL, handler) {
      let params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this._resourceURL = resourceURL;
      this._handler = handler;
      this._params = params;
      this._fetchedAll = false;
    }
    get loadMoreURL() {
      return this.meta?.load_more_url;
    }
    get totalRows() {
      return this.meta?.total_rows;
    }
    get length() {
      return this.items?.length;
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Iteration_protocols
    [Symbol.iterator]() {
      let index = 0;
      return {
        next: () => {
          if (index < this.length) {
            return {
              value: this.items[index++],
              done: false
            };
          } else {
            return {
              done: true
            };
          }
        }
      };
    }

    /**
     * Loads first batch of results
     * @returns {Promise}
     */
    load() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.loading || this._fetchedAll || this.totalRows && this.items.length >= this.totalRows) {
        return _rsvp.Promise.resolve();
      }
      this.loading = true;
      let endpoint;
      if (this.loadMoreURL) {
        endpoint = this.loadMoreURL;
      } else {
        const filteredQueryParams = Object.entries(params).filter(_ref => {
          let [, v] = _ref;
          return v !== undefined;
        });
        const queryString = new URLSearchParams(filteredQueryParams).toString();
        endpoint = this._resourceURL + (queryString ? `?${queryString}` : "");
      }
      return this.#fetch(endpoint).then(result => {
        const items = this._handler(result);
        if (items.length) {
          this.items = (this.items ?? []).concat(items);
        }
        if (!items.length || items.length < params.limit) {
          this._fetchedAll = true;
        }
        this.meta = result.meta;
        this.fetchedOnce = true;
      }).finally(() => {
        this.loading = false;
      });
    }
    static #_5 = dt7948.n(this.prototype, "load", [_decorators.bind]);
    #fetch(url) {
      return (0, _ajax.ajax)(url, {
        type: "GET",
        data: this._params
      });
    }
  }
  _exports.default = Collection;
});