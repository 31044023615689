define("discourse/plugins/chat/discourse/routes/chat-channel-decorator", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withChatChannel;
  function withChatChannel(extendedClass) {
    return class WithChatChannel extends extendedClass {
      static #_ = dt7948.g(this.prototype, "chatChannelsManager", [_service.service]);
      #chatChannelsManager = (dt7948.i(this, "chatChannelsManager"), void 0);
      static #_2 = dt7948.g(this.prototype, "chat", [_service.service]);
      #chat = (dt7948.i(this, "chat"), void 0);
      static #_3 = dt7948.g(this.prototype, "router", [_service.service]);
      #router = (dt7948.i(this, "router"), void 0);
      async model(params) {
        return this.chatChannelsManager.find(params.channelId);
      }
      titleToken() {
        if (!this.currentModel) {
          return;
        }
        if (this.currentModel.isDirectMessageChannel) {
          return `${this.currentModel.title}`;
        } else {
          return `#${this.currentModel.title}`;
        }
      }
      afterModel(model) {
        super.afterModel?.(...arguments);
        this.chat.activeChannel = model;
        if (!model) {
          return this.router.replaceWith("chat");
        }
        let {
          channelTitle
        } = this.paramsFor(this.routeName);
        if (channelTitle && channelTitle !== model.slugifiedTitle) {
          if (this.routeName === "chat.channel.info") {
            return this.router.replaceWith("chat.channel.info", ...model.routeModels);
          }
          const messageId = this.paramsFor("chat.channel.near-message").messageId;
          const threadId = this.paramsFor("chat.channel.thread").threadId;
          if (threadId) {
            const threadMessageId = this.paramsFor("chat.channel.thread.near-message").messageId;
            if (threadMessageId) {
              this.router.replaceWith("chat.channel.thread.near-message", ...model.routeModels, threadId, threadMessageId);
            } else {
              this.router.replaceWith("chat.channel.thread", ...model.routeModels, threadId);
            }
          } else if (messageId) {
            this.router.replaceWith("chat.channel.near-message", ...model.routeModels, messageId);
          } else {
            this.router.replaceWith("chat.channel", ...model.routeModels);
          }
        } else {
          this.controllerFor("chat-channel").set("targetMessageId", null);
        }
      }
    };
  }
});