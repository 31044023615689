define("discourse/plugins/chat/discourse/models/chat-thread-preview", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadPreview {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatThreadPreview(args);
    }
    static #_ = dt7948.g(this.prototype, "replyCount", [_tracking.tracked]);
    #replyCount = (dt7948.i(this, "replyCount"), void 0);
    static #_2 = dt7948.g(this.prototype, "lastReplyId", [_tracking.tracked]);
    #lastReplyId = (dt7948.i(this, "lastReplyId"), void 0);
    static #_3 = dt7948.g(this.prototype, "lastReplyCreatedAt", [_tracking.tracked]);
    #lastReplyCreatedAt = (dt7948.i(this, "lastReplyCreatedAt"), void 0);
    static #_4 = dt7948.g(this.prototype, "lastReplyExcerpt", [_tracking.tracked]);
    #lastReplyExcerpt = (dt7948.i(this, "lastReplyExcerpt"), void 0);
    static #_5 = dt7948.g(this.prototype, "lastReplyUser", [_tracking.tracked]);
    #lastReplyUser = (dt7948.i(this, "lastReplyUser"), void 0);
    static #_6 = dt7948.g(this.prototype, "participantCount", [_tracking.tracked]);
    #participantCount = (dt7948.i(this, "participantCount"), void 0);
    static #_7 = dt7948.g(this.prototype, "participantUsers", [_tracking.tracked]);
    #participantUsers = (dt7948.i(this, "participantUsers"), void 0);
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (!args) {
        args = {};
      }
      this.update(args);
    }
    get otherParticipantCount() {
      return this.participantCount - this.participantUsers.length;
    }
    update() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.replyCount = args.reply_count || args.replyCount || 0;
      this.lastReplyId = args.last_reply_id || args.lastReplyId;
      this.lastReplyCreatedAt = new Date(args.last_reply_created_at || args.lastReplyCreatedAt);
      this.lastReplyExcerpt = args.last_reply_excerpt || args.lastReplyExcerpt;
      this.participantCount = args.participant_count || args.participantCount || 0;

      // cheap trick to avoid avatars flickering
      const lastReplyUser = args.last_reply_user || args.lastReplyUser;
      if (lastReplyUser?.id !== this.lastReplyUser?.id) {
        this.lastReplyUser = lastReplyUser;
      }

      // cheap trick to avoid avatars flickering
      const participantUsers = args.participant_users || args.participantUsers || [];
      if (participantUsers?.map(u => u.id).join(",") !== this.participantUsers?.map(u => u.id).join(",")) {
        this.participantUsers = participantUsers;
      }
    }
  }
  _exports.default = ChatThreadPreview;
});