define("discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse-common/helpers/d-icon", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _dIcon, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarCloseThreadsButton extends _component.default {
    static #_ = dt7948.g(this.prototype, "site", [_service.service]);
    #site = (dt7948.i(this, "site"), void 0);
    closeButtonTitle = _I18n.default.t("chat.thread.close");
    static #_2 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.desktopView}}
          <LinkTo
            class="c-navbar__close-threads-button btn-transparent btn btn-icon no-text"
            @route="chat.channel"
            @models={{@channel.routeModels}}
            title={{this.closeButtonTitle}}
          >
            {{icon "times"}}
          </LinkTo>
        {{/if}}
      
    */
    {
      "id": "J2ypjGnF",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__close-threads-button btn-transparent btn btn-icon no-text\"],[16,\"title\",[30,0,[\"closeButtonTitle\"]]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"times\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button.js",
      "scope": () => [_routing.LinkTo, _dIcon.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatNavbarCloseThreadsButton;
});