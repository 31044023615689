define("discourse/plugins/chat/discourse/routes/chat-threads", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelThreads extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatStateManager", [_service.service]);
    #chatStateManager = (dt7948.i(this, "chatStateManager"), void 0);
    activate() {
      this.chat.activeChannel = null;
      this.chatStateManager.closeSidePanel();
    }
  }
  _exports.default = ChatChannelThreads;
});