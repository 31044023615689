define("discourse/plugins/chat/discourse/components/chat-notice", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _mention_without_membership, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPONENT_DICT = {
    mention_without_membership: _mention_without_membership.default
  };
  class ChatNotices extends _component.default {
    static #_ = dt7948.g(this.prototype, "noticesManager", [(0, _service.service)("chat-channel-notices-manager")]);
    #noticesManager = (dt7948.i(this, "noticesManager"), void 0);
    clearNotice() {
      this.noticesManager.clearNotice(this.args.notice);
    }
    static #_2 = dt7948.n(this.prototype, "clearNotice", [_object.action]);
    get component() {
      return COMPONENT_DICT[this.args.notice.type];
    }
    static #_3 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-notices__notice">
    
          {{#if @notice.textContent}}
            <p class="chat-notices__notice__content">
              {{@notice.textContent}}
            </p>
          {{else}}
            <this.component
              @channel={{@channel}}
              @notice={{@notice}}
              @clearNotice={{this.clearNotice}}
            />
          {{/if}}
    
          <DButton
            @icon="times"
            @action={{this.clearNotice}}
            class="btn-transparent chat-notices__notice__clear"
          />
        </div>
      
    */
    {
      "id": "uLTQ0Y/X",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices__notice\"],[12],[1,\"\\n\\n\"],[41,[30,1,[\"textContent\"]],[[[1,\"        \"],[10,2],[14,0,\"chat-notices__notice__content\"],[12],[1,\"\\n          \"],[1,[30,1,[\"textContent\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,0,[\"component\"]],null,[[\"@channel\",\"@notice\",\"@clearNotice\"],[[30,2],[30,1],[30,0,[\"clearNotice\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-transparent chat-notices__notice__clear\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"clearNotice\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@notice\",\"@channel\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notice.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatNotices;
});