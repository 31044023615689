define("discourse/plugins/chat/discourse/controllers/chat", ["exports", "@ember/controller", "@ember/service", "discourse/plugins/chat/discourse/lib/chat-constants"], function (_exports, _controller, _service, _chatConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatController extends _controller.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatStateManager", [_service.service]);
    #chatStateManager = (dt7948.i(this, "chatStateManager"), void 0);
    static #_3 = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    get shouldUseChatSidebar() {
      if (this.site.mobileView) {
        return false;
      }
      if (this.shouldUseCoreSidebar) {
        return false;
      }
      return true;
    }
    get shouldUseCoreSidebar() {
      return this.siteSettings.navigation_menu === "sidebar";
    }
    get shouldUseChatFooter() {
      return this.site.mobileView && _chatConstants.FOOTER_NAV_ROUTES.includes(this.router.currentRouteName);
    }
    get mainOutletModifierClasses() {
      let modifierClasses = [];
      if (this.chatStateManager.isSidePanelExpanded) {
        modifierClasses.push("has-side-panel-expanded");
      }
      if (!this.router.currentRouteName.startsWith("chat.channel.info") && !this.router.currentRouteName.startsWith("chat.browse")) {
        modifierClasses.push("chat-view");
      }
      return modifierClasses.join(" ");
    }
  }
  _exports.default = ChatController;
});