define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _didUpdate, _service, _ajaxError, _discourseI18n, _navbar, _chatThread, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannelThread extends _component.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatStateManager", [_service.service]);
    #chatStateManager = (dt7948.i(this, "chatStateManager"), void 0);
    static #_3 = dt7948.g(this.prototype, "chatChannelsManager", [_service.service]);
    #chatChannelsManager = (dt7948.i(this, "chatChannelsManager"), void 0);
    static #_4 = dt7948.g(this.prototype, "chatHistory", [_service.service]);
    #chatHistory = (dt7948.i(this, "chatHistory"), void 0);
    static #_5 = dt7948.g(this.prototype, "showThreadFullTitle", [_tracking.tracked], function () {
      return false;
    });
    #showThreadFullTitle = (dt7948.i(this, "showThreadFullTitle"), void 0);
    get showfullTitle() {
      return this.chatStateManager.isDrawerExpanded && this.showThreadFullTitle;
    }
    get backButton() {
      const link1 = {
        models: this.chat.activeChannel?.routeModels
      };
      if (this.chatHistory.previousRoute?.name === "chat.channel.threads") {
        link1.title = _discourseI18n.default.t("chat.return_to_threads_list");
        link1.route = "chat.channel.threads";
      } else if (this.chatHistory.previousRoute?.name === "chat.threads") {
        link1.title = _discourseI18n.default.t("chat.my_threads.title");
        link1.route = "chat.threads";
        link1.models = [];
      } else {
        link1.title = _discourseI18n.default.t("chat.return_to_channel");
        link1.route = "chat.channel";
      }
      return link1;
    }
    get threadTitle() {
      return this.chat.activeChannel?.activeThread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    async fetchChannelAndThread() {
      if (!this.args.params?.channelId || !this.args.params?.threadId) {
        return;
      }
      try {
        const channel1 = await this.chatChannelsManager.find(this.args.params.channelId);
        this.chat.activeChannel = channel1;
        channel1.threadsManager.find(channel1.id, this.args.params.threadId).then(thread1 => {
          this.chat.activeChannel.activeThread = thread1;
        });
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_6 = dt7948.n(this.prototype, "fetchChannelAndThread", [_object.action]);
    setFullTitle(value1) {
      this.showThreadFullTitle = value1;
    }
    static #_7 = dt7948.n(this.prototype, "setFullTitle", [_object.action]);
    static #_8 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Navbar
          @onClick={{this.chat.toggleDrawer}}
          @showFullTitle={{this.showfullTitle}}
          as |navbar|
        >
          <navbar.BackButton
            @title={{this.backButton.title}}
            @route={{this.backButton.route}}
            @routeModels={{this.backButton.models}}
          />
          <navbar.Title @title={{this.threadTitle}} @icon="discourse-threads" />
          <navbar.Actions as |a|>
            <a.ToggleDrawerButton />
            <a.FullPageButton />
            <a.CloseDrawerButton />
          </navbar.Actions>
        </Navbar>
    
        {{#if this.chatStateManager.isDrawerExpanded}}
          <div
            class="chat-drawer-content"
            {{didInsert this.fetchChannelAndThread}}
            {{didUpdate this.fetchChannelAndThread @params.channelId}}
            {{didUpdate this.fetchChannelAndThread @params.threadId}}
          >
            {{#each (array this.chat.activeChannel.activeThread) as |thread|}}
              {{#if thread}}
                <ChatThread
                  @thread={{thread}}
                  @targetMessageId={{@params.messageId}}
                  @setFullTitle={{this.setFullTitle}}
                />
              {{/if}}
            {{/each}}
          </div>
        {{/if}}
      
    */
    {
      "id": "23Z/sxxL",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@onClick\",\"@showFullTitle\"],[[30,0,[\"chat\",\"toggleDrawer\"]],[30,0,[\"showfullTitle\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backButton\",\"title\"]],[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],null],[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"threadTitle\"]],\"discourse-threads\"]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n        \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannelAndThread\"]]],null],[4,[32,2],[[30,0,[\"fetchChannelAndThread\"]],[30,3,[\"channelId\"]]],null],[4,[32,2],[[30,0,[\"fetchChannelAndThread\"]],[30,3,[\"threadId\"]]],null],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[28,[32,3],[[30,0,[\"chat\",\"activeChannel\",\"activeThread\"]]],null]],null]],null],null,[[[41,[30,4],[[[1,\"            \"],[8,[32,4],null,[[\"@thread\",\"@targetMessageId\",\"@setFullTitle\"],[[30,4],[30,3,[\"messageId\"]],[30,0,[\"setFullTitle\"]]]],null],[1,\"\\n\"]],[]],null]],[4]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"navbar\",\"a\",\"@params\",\"thread\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread.js",
      "scope": () => [_navbar.default, _didInsert.default, _didUpdate.default, _helper.array, _chatThread.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatDrawerRoutesChannelThread;
});