define("discourse/plugins/chat/discourse/routes/chat-browse-archived", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatBrowseIndexRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_2 = dt7948.g(this.prototype, "siteSettings", [_service.service]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    afterModel() {
      if (!this.siteSettings.chat_allow_archiving_channels) {
        this.router.replaceWith("chat.browse");
      }
    }
  }
  _exports.default = ChatBrowseIndexRoute;
});