define("discourse/plugins/chat/discourse/components/chat-side-panel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "@ember/template", "truth-helpers", "discourse/plugins/chat/discourse/modifiers/chat/resizable-node", "discourse/plugins/chat/discourse/components/chat-side-panel-resizer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _service, _template2, _truthHelpers, _resizableNode, _chatSidePanelResizer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_CHAT_CHANNEL_WIDTH = 250;
  class ChatSidePanel extends _component.default {
    static #_ = dt7948.g(this.prototype, "chatStateManager", [_service.service]);
    #chatStateManager = (dt7948.i(this, "chatStateManager"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatSidePanelSize", [_service.service]);
    #chatSidePanelSize = (dt7948.i(this, "chatSidePanelSize"), void 0);
    static #_3 = dt7948.g(this.prototype, "site", [_service.service]);
    #site = (dt7948.i(this, "site"), void 0);
    static #_4 = dt7948.g(this.prototype, "widthStyle", [_tracking.tracked]);
    #widthStyle = (dt7948.i(this, "widthStyle"), void 0);
    setupSize() {
      this.widthStyle = (0, _template2.htmlSafe)(`width:${this.chatSidePanelSize.width}px`);
    }
    static #_5 = dt7948.n(this.prototype, "setupSize", [_object.action]);
    didResize(element1, size1) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      const parentWidth1 = element1.parentElement.getBoundingClientRect().width;
      const mainPanelWidth1 = parentWidth1 - size1.width;
      if (mainPanelWidth1 >= MIN_CHAT_CHANNEL_WIDTH) {
        this.chatSidePanelSize.width = size1.width;
        element1.style.width = size1.width + "px";
        this.widthStyle = (0, _template2.htmlSafe)(`width:${size1.width}px`);
      }
    }
    static #_6 = dt7948.n(this.prototype, "didResize", [_object.action]);
    #maxWidth(element1) {
      const parentWidth1 = element1.parentElement.getBoundingClientRect().width;
      return parentWidth1 - MIN_CHAT_CHANNEL_WIDTH;
    }
    static #_7 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.chatStateManager.isSidePanelExpanded}}
          <div
            class="chat-side-panel"
            {{didInsert this.setupSize}}
            {{resizableNode
              ".chat-side-panel-resizer"
              this.didResize
              (hash
                position=false vertical=false mutate=false resetOnWindowResize=true
              )
            }}
            style={{if
              (and this.site.desktopView this.chatStateManager.isFullPageActive)
              this.widthStyle
            }}
          >
            {{yield}}
    
            {{#if this.site.desktopView}}
              <ChatSidePanelResizer />
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "1KF7uXu1",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"isSidePanelExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-side-panel\"],[16,5,[52,[28,[32,0],[[30,0,[\"site\",\"desktopView\"]],[30,0,[\"chatStateManager\",\"isFullPageActive\"]]],null],[30,0,[\"widthStyle\"]]]],[4,[32,1],[[30,0,[\"setupSize\"]]],null],[4,[32,2],[\".chat-side-panel-resizer\",[30,0,[\"didResize\"]],[28,[32,3],null,[[\"position\",\"vertical\",\"mutate\",\"resetOnWindowResize\"],[false,false,false,true]]]],null],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"          \"],[8,[32,4],null,null,null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-side-panel.js",
      "scope": () => [_truthHelpers.and, _didInsert.default, _resizableNode.default, _helper.hash, _chatSidePanelResizer.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatSidePanel;
});