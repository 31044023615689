define("discourse/plugins/chat/discourse/services/chat-channel-scroll-positions", ["exports", "@glimmer/tracking", "@ember/service", "@ember-compat/tracked-built-ins"], function (_exports, _tracking, _service, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelScrollPositions extends _service.default {
    static #_ = dt7948.g(this.prototype, "positions", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedMap();
    });
    #positions = (dt7948.i(this, "positions"), void 0);
    get(id) {
      return this.positions.get(id);
    }
    set(id, position) {
      this.positions.set(id, position);
    }
    delete(id) {
      if (this.positions.has(id)) {
        this.positions.delete(id);
      }
    }
  }
  _exports.default = ChatChannelScrollPositions;
});