define("discourse/plugins/chat/discourse/routes/chat-channel-info", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/chat/discourse/services/chat-channel-info-route-origin-manager", "discourse/plugins/chat/discourse/routes/chat-channel-decorator"], function (_exports, _service, _discourse, _chatChannelInfoRouteOriginManager, _chatChannelDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatChannelInfoRoute = dt7948.c(class ChatChannelInfoRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "chatChannelInfoRouteOriginManager", [_service.service]);
    #chatChannelInfoRouteOriginManager = (dt7948.i(this, "chatChannelInfoRouteOriginManager"), void 0);
    activate(transition) {
      const name = transition?.from?.name;
      if (name) {
        this.chatChannelInfoRouteOriginManager.origin = name.startsWith("chat.browse") ? _chatChannelInfoRouteOriginManager.ORIGINS.browse : _chatChannelInfoRouteOriginManager.ORIGINS.channel;
      }
    }
    deactivate() {
      this.chatChannelInfoRouteOriginManager.origin = null;
    }
  }, [_chatChannelDecorator.default]);
  var _default = _exports.default = ChatChannelInfoRoute;
});