define("discourse/plugins/chat/discourse/components/dc-filter-input", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "ember-modifier", "discourse/helpers/concat-class", "discourse/helpers/noop", "discourse-common/helpers/d-icon", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _emberModifier, _concatClass, _noop, _dIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DcFilterInput extends _component.default {
    static #_ = dt7948.g(this.prototype, "isFocused", [_tracking.tracked], function () {
      return false;
    });
    #isFocused = (dt7948.i(this, "isFocused"), void 0);
    focusState = (0, _emberModifier.modifier)(element1 => {
      const focusInHandler1 = () => {
        this.isFocused = true;
      };
      const focusOutHandler1 = () => {
        this.isFocused = false;
      };
      element1.addEventListener("focusin", focusInHandler1);
      element1.addEventListener("focusout", focusOutHandler1);
      return () => {
        element1.removeEventListener("focusin", focusInHandler1);
        element1.removeEventListener("focusout", focusOutHandler1);
      };
    });
    static #_2 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class={{concatClass
            @containerClass
            "dc-filter-input-container"
            (if this.isFocused "is-focused")
          }}
        >
          {{#if @icons.left}}
            {{icon @icons.left class="-left"}}
          {{/if}}
    
          <Input
            {{this.focusState}}
            {{on "input" (if @filterAction @filterAction (noop))}}
            @value={{@value}}
            class="dc-filter-input"
            ...attributes
          />
    
          {{yield}}
    
          {{#if @icons.right}}
            {{icon @icons.right class="-right"}}
          {{/if}}
        </div>
      
    */
    {
      "id": "oVtoWO4Q",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[[30,1],\"dc-filter-input-container\",[52,[30,0,[\"isFocused\"]],\"is-focused\"]],null]],[12],[1,\"\\n\"],[41,[30,2,[\"left\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,2,[\"left\"]]],[[\"class\"],[\"-left\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"dc-filter-input\"],[17,3],[4,[30,0,[\"focusState\"]],null,null],[4,[32,3],[\"input\",[52,[30,5],[30,5],[28,[32,4],null,null]]],null]],[[\"@value\"],[[30,4]]],null],[1,\"\\n\\n      \"],[18,6,null],[1,\"\\n\\n\"],[41,[30,2,[\"right\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,2,[\"right\"]]],[[\"class\"],[\"-right\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@containerClass\",\"@icons\",\"&attrs\",\"@value\",\"@filterAction\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/dc-filter-input.js",
      "scope": () => [_concatClass.default, _dIcon.default, _component2.Input, _modifier.on, _noop.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = DcFilterInput;
});