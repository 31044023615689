define("discourse/plugins/chat/discourse/lib/chat-threads-manager", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "@ember-compat/tracked-built-ins", "rsvp", "discourse/plugins/chat/discourse/models/chat-thread"], function (_exports, _tracking, _application, _service, _trackedBuiltIns, _rsvp, _chatThread) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    The ChatThreadsManager is responsible for managing the loaded chat threads
    for a ChatChannel model.
  
    It provides helpers to facilitate using and managing loaded threads instead of constantly
    fetching them from the server.
  */

  class ChatThreadsManager {
    static #_ = dt7948.g(this.prototype, "chatTrackingStateManager", [_service.service]);
    #chatTrackingStateManager = (dt7948.i(this, "chatTrackingStateManager"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatChannelsManager", [_service.service]);
    #chatChannelsManager = (dt7948.i(this, "chatChannelsManager"), void 0);
    static #_3 = dt7948.g(this.prototype, "chatApi", [_service.service]);
    #chatApi = (dt7948.i(this, "chatApi"), void 0);
    static #_4 = dt7948.g(this.prototype, "_cached", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedObject();
    });
    #_cached = (dt7948.i(this, "_cached"), void 0);
    static #_5 = dt7948.g(this.prototype, "_unreadThreadOverview", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedMap();
    });
    #_unreadThreadOverview = (dt7948.i(this, "_unreadThreadOverview"), void 0);
    constructor(owner) {
      (0, _application.setOwner)(this, owner);
    }
    get unreadThreadCount() {
      return this.unreadThreadOverview.size;
    }
    get unreadThreadOverview() {
      return this._unreadThreadOverview;
    }
    set unreadThreadOverview(unreadThreadOverview) {
      this._unreadThreadOverview.clear();
      for (const [threadId, lastReplyCreatedAt] of Object.entries(unreadThreadOverview)) {
        this.markThreadUnread(threadId, lastReplyCreatedAt);
      }
    }
    markThreadUnread(threadId, lastReplyCreatedAt) {
      this.unreadThreadOverview.set(parseInt(threadId, 10), new Date(lastReplyCreatedAt));
    }
    get threads() {
      return Object.values(this._cached);
    }
    static #_6 = dt7948.n(this.prototype, "threads", [_tracking.cached]);
    async find(channelId, threadId) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        fetchIfNotFound: true
      };
      const existingThread = this.#getFromCache(threadId);
      if (existingThread) {
        return _rsvp.default.resolve(existingThread);
      } else if (options.fetchIfNotFound) {
        return this.#fetchFromServer(channelId, threadId);
      } else {
        return _rsvp.default.resolve();
      }
    }
    remove(threadObject) {
      delete this._cached[threadObject.id];
    }
    add(channel, threadObject) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let model;
      if (!options.replace) {
        model = this.#getFromCache(threadObject.id);
      }
      if (!model) {
        if (threadObject instanceof _chatThread.default) {
          model = threadObject;
        } else {
          model = _chatThread.default.create(channel, threadObject);
        }
        this.#cache(model);
      }
      if (threadObject?.meta?.message_bus_last_ids?.thread_message_bus_last_id) {
        model.threadMessageBusLastId = threadObject.meta.message_bus_last_ids.thread_message_bus_last_id;
      }
      return model;
    }
    #cache(thread) {
      this._cached[thread.id] = thread;
    }
    #getFromCache(id) {
      return this._cached[id];
    }
    async #fetchFromServer(channelId, threadId) {
      return this.chatApi.thread(channelId, threadId).then(result => {
        return this.chatChannelsManager.find(channelId).then(channel => {
          return channel.threadsManager.add(channel, result.thread);
        });
      });
    }
  }
  _exports.default = ChatThreadsManager;
});