define("discourse/plugins/chat/discourse/services/chat-history", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatHistory extends _service.default {
    static #_ = dt7948.g(this.prototype, "history", [_tracking.tracked]);
    #history = (dt7948.i(this, "history"), void 0);
    get previousRoute() {
      if (this.history?.length > 1) {
        return this.history[this.history.length - 2];
      }
    }
    get currentRoute() {
      if (this.history?.length > 0) {
        return this.history[this.history.length - 1];
      }
    }
    visit(route) {
      this.history = (this.history || []).slice(-9).concat([route]);
    }
  }
  _exports.default = ChatHistory;
});