define("discourse/plugins/chat/discourse/routes/chat", ["exports", "@ember/runloop", "@ember/service", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse/mixins/scroll-top", "discourse/routes/discourse", "discourse-i18n", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", "discourse/plugins/chat/discourse/lib/init-sidebar-state"], function (_exports, _runloop, _service, _pluginApi, _utilities, _scrollTop, _discourse, _discourseI18n, _getUserChatSeparateSidebarMode, _initSidebarState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
    static #_2 = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_3 = dt7948.g(this.prototype, "chatStateManager", [_service.service]);
    #chatStateManager = (dt7948.i(this, "chatStateManager"), void 0);
    static #_4 = dt7948.g(this.prototype, "currentUser", [_service.service]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    titleToken() {
      return _discourseI18n.default.t("chat.title_capitalized");
    }
    beforeModel(transition) {
      if (!this.chat.userCanChat) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
      const INTERCEPTABLE_ROUTES = ["chat.channel", "chat.direct-messages", "chat.channels", "chat.threads", "chat.channel.thread", "chat.channel.thread.index", "chat.channel.thread.near-message", "chat.channel.threads", "chat.channel.index", "chat.channel.near-message", "chat.channel-legacy", "chat", "chat.index"];
      if (transition.from &&
      // don't intercept when directly loading chat
      this.chatStateManager.isDrawerPreferred && INTERCEPTABLE_ROUTES.includes(transition.targetName)) {
        transition.abort();
        let url = transition.intent.url;
        if (transition.targetName.startsWith("chat.channel")) {
          url ??= this.router.urlFor(transition.targetName, ...transition.intent.contexts);
        } else {
          url ??= this.router.urlFor(transition.targetName);
        }
        this.appEvents.trigger("chat:open-url", url);
        return;
      }
      this.appEvents.trigger("chat:toggle-close");
    }
    activate() {
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        api.setSidebarPanel(_initSidebarState.CHAT_PANEL);
        const chatSeparateSidebarMode = (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
        if (chatSeparateSidebarMode.never) {
          api.setCombinedSidebarMode();
          api.hideSidebarSwitchPanelButtons();
        } else {
          api.setSeparatedSidebarMode();
        }
      });
      this.chatStateManager.storeAppURL();
      this.chat.updatePresence();
      (0, _runloop.schedule)("afterRender", () => {
        document.body.classList.add("has-full-page-chat");
        document.documentElement.classList.add("has-full-page-chat");
        (0, _scrollTop.scrollTop)();
      });
    }
    deactivate(transition) {
      (0, _pluginApi.withPluginApi)("1.8.0", api => {
        (0, _initSidebarState.initSidebarState)(api, this.currentUser);
      });
      if (transition) {
        const url = this.router.urlFor(transition.from.name);
        this.chatStateManager.storeChatURL(url);
      }
      this.chat.activeChannel = null;
      this.chat.updatePresence();
      (0, _runloop.schedule)("afterRender", () => {
        document.body.classList.remove("has-full-page-chat");
        document.documentElement.classList.remove("has-full-page-chat");
      });
    }
  }
  _exports.default = ChatRoute;
});