define("discourse/plugins/chat/discourse/models/chat-channel-archive", ["exports", "@glimmer/tracking"], function (_exports, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelArchive {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatChannelArchive(args);
    }
    static #_ = dt7948.g(this.prototype, "failed", [_tracking.tracked]);
    #failed = (dt7948.i(this, "failed"), void 0);
    static #_2 = dt7948.g(this.prototype, "completed", [_tracking.tracked]);
    #completed = (dt7948.i(this, "completed"), void 0);
    static #_3 = dt7948.g(this.prototype, "messages", [_tracking.tracked]);
    #messages = (dt7948.i(this, "messages"), void 0);
    static #_4 = dt7948.g(this.prototype, "topicId", [_tracking.tracked]);
    #topicId = (dt7948.i(this, "topicId"), void 0);
    static #_5 = dt7948.g(this.prototype, "totalMessages", [_tracking.tracked]);
    #totalMessages = (dt7948.i(this, "totalMessages"), void 0);
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.failed = args.archive_failed;
      this.completed = args.archive_completed;
      this.messages = args.archived_messages;
      this.topicId = args.archive_topic_id;
      this.totalMessages = args.total_messages;
    }
  }
  _exports.default = ChatChannelArchive;
});