define("discourse/plugins/chat/discourse/controllers/chat-draft-channel", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDraftChannelController extends _controller.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
  }
  _exports.default = ChatDraftChannelController;
});