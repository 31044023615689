define("discourse/plugins/chat/discourse/services/chat-composer-presence-manager", ["exports", "@ember/runloop", "@ember/service", "discourse-common/config/environment"], function (_exports, _runloop, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEEP_ALIVE_DURATION_SECONDS = 10;

  // This service is loosely based on discourse-presence's ComposerPresenceManager service
  // It is a singleton which receives notifications each time the value of the chat composer changes
  // This service ensures that a single browser can only be 'replying' to a single chatChannel at
  // one time, and automatically 'leaves' the channel if the composer value hasn't changed for 10 seconds
  class ChatComposerPresenceManager extends _service.default {
    static #_ = dt7948.g(this.prototype, "presence", [_service.service]);
    #presence = (dt7948.i(this, "presence"), void 0);
    willDestroy() {
      this.leave();
    }
    notifyState(channelName, replying) {
      if (!replying) {
        this.leave();
        return;
      }
      if (this._channelName !== channelName) {
        this._enter(channelName);
        this._channelName = channelName;
      }
      if (!(0, _environment.isTesting)()) {
        this._autoLeaveTimer = (0, _runloop.debounce)(this, this.leave, KEEP_ALIVE_DURATION_SECONDS * 1000);
      }
    }
    leave() {
      this._presentChannel?.leave();
      this._presentChannel = null;
      this._channelName = null;
      if (this._autoLeaveTimer) {
        (0, _runloop.cancel)(this._autoLeaveTimer);
        this._autoLeaveTimer = null;
      }
    }
    _enter(channelName) {
      this.leave();
      this._presentChannel = this.presence.getChannel(channelName);
      this._presentChannel.enter();
    }
  }
  _exports.default = ChatComposerPresenceManager;
});