define("discourse/plugins/chat/discourse/controllers/preferences-chat", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/services/chat-audio-manager"], function (_exports, _controller, _object, _service, _ajaxError, _environment, _decorators, _discourseI18n, _chatAudioManager3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = void 0;
  const CHAT_ATTRS = ["chat_enabled", "only_chat_push_notifications", "ignore_channel_wide_mention", "show_thread_title_prompts", "chat_sound", "chat_email_frequency", "chat_header_indicator_preference", "chat_separate_sidebar_mode"];
  const EMAIL_FREQUENCY_OPTIONS = [{
    name: _discourseI18n.default.t("chat.email_frequency.never"),
    value: "never"
  }, {
    name: _discourseI18n.default.t("chat.email_frequency.when_away"),
    value: "when_away"
  }];
  const HEADER_INDICATOR_PREFERENCE_NEVER = _exports.HEADER_INDICATOR_PREFERENCE_NEVER = "never";
  const HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS = "dm_and_mentions";
  const HEADER_INDICATOR_PREFERENCE_ALL_NEW = _exports.HEADER_INDICATOR_PREFERENCE_ALL_NEW = "all_new";
  const HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = _exports.HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS = "only_mentions";
  const HEADER_INDICATOR_OPTIONS = [{
    name: _discourseI18n.default.t("chat.header_indicator_preference.all_new"),
    value: HEADER_INDICATOR_PREFERENCE_ALL_NEW
  }, {
    name: _discourseI18n.default.t("chat.header_indicator_preference.dm_and_mentions"),
    value: HEADER_INDICATOR_PREFERENCE_DM_AND_MENTIONS
  }, {
    name: _discourseI18n.default.t("chat.header_indicator_preference.only_mentions"),
    value: HEADER_INDICATOR_PREFERENCE_ONLY_MENTIONS
  }, {
    name: _discourseI18n.default.t("chat.header_indicator_preference.never"),
    value: HEADER_INDICATOR_PREFERENCE_NEVER
  }];
  const CHAT_SEPARATE_SIDEBAR_MODE_OPTIONS = [{
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.always"),
    value: "always"
  }, {
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.fullscreen"),
    value: "fullscreen"
  }, {
    name: _discourseI18n.default.t("admin.site_settings.chat_separate_sidebar_mode.never"),
    value: "never"
  }];
  class PreferencesChatController extends _controller.default {
    static #_ = dt7948.g(this.prototype, "chatAudioManager", [_service.service]);
    #chatAudioManager = (dt7948.i(this, "chatAudioManager"), void 0);
    static #_2 = dt7948.g(this.prototype, "siteSettings", [_service.service]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    subpageTitle = _discourseI18n.default.t("chat.admin.title");
    emailFrequencyOptions = EMAIL_FREQUENCY_OPTIONS;
    headerIndicatorOptions = HEADER_INDICATOR_OPTIONS;
    chatSeparateSidebarModeOptions = CHAT_SEPARATE_SIDEBAR_MODE_OPTIONS;
    get chatSeparateSidebarMode() {
      const mode = this.model.get("user_option.chat_separate_sidebar_mode");
      if (mode === "default") {
        return this.siteSettings.chat_separate_sidebar_mode;
      } else {
        return mode;
      }
    }
    chatSounds() {
      return Object.keys(_chatAudioManager3.CHAT_SOUNDS).map(value => {
        return {
          name: _discourseI18n.default.t(`chat.sounds.${value}`),
          value
        };
      });
    }
    static #_3 = dt7948.n(this.prototype, "chatSounds", [_decorators.default]);
    onChangeChatSound(sound) {
      if (sound) {
        this.chatAudioManager.playImmediately(sound);
      }
      this.model.set("user_option.chat_sound", sound);
    }
    static #_4 = dt7948.n(this.prototype, "onChangeChatSound", [_object.action]);
    save() {
      this.set("saved", false);
      return this.model.save(CHAT_ATTRS).then(() => {
        this.set("saved", true);
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_5 = dt7948.n(this.prototype, "save", [_object.action]);
  }
  _exports.default = PreferencesChatController;
});