define("discourse/plugins/chat/discourse/components/chat/composer/thread", ["exports", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/components/chat-composer"], function (_exports, _object, _service, _decorators, _discourseI18n, _chatMessage, _chatComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatComposerThread extends _chatComposer.default {
    static #_ = dt7948.g(this.prototype, "channelComposer", [(0, _service.service)("chat-channel-composer")]);
    #channelComposer = (dt7948.i(this, "channelComposer"), void 0);
    static #_2 = dt7948.g(this.prototype, "composer", [(0, _service.service)("chat-thread-composer")]);
    #composer = (dt7948.i(this, "composer"), void 0);
    static #_3 = dt7948.g(this.prototype, "pane", [(0, _service.service)("chat-thread-pane")]);
    #pane = (dt7948.i(this, "pane"), void 0);
    static #_4 = dt7948.g(this.prototype, "currentUser", [_service.service]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_5 = dt7948.g(this.prototype, "chatDraftsManager", [_service.service]);
    #chatDraftsManager = (dt7948.i(this, "chatDraftsManager"), void 0);
    context = "thread";
    composerId = "thread-composer";
    persistDraft() {
      this.chatDraftsManager.add(this.draft, this.args.thread.channel.id, this.args.thread.id);
    }
    static #_6 = dt7948.n(this.prototype, "persistDraft", [(0, _decorators.debounce)(2000)]);
    destroyDraft() {
      this.chatDraftsManager.remove(this.args.thread.channel.id, this.args.thread.id);
    }
    static #_7 = dt7948.n(this.prototype, "destroyDraft", [_object.action]);
    resetDraft() {
      this.args.thread.resetDraft(this.currentUser);
    }
    static #_8 = dt7948.n(this.prototype, "resetDraft", [_object.action]);
    get draft() {
      return this.args.thread.draft;
    }
    get disabled() {
      return !this.chat.userCanInteractWithChat || !this.args.thread.channel.canModifyMessages(this.currentUser);
    }
    get presenceChannelName() {
      const thread = this.args.thread;
      return `/chat-reply/${thread.channel.id}/thread/${thread.id}`;
    }
    get placeholder() {
      return _discourseI18n.default.t("chat.placeholder_thread");
    }
    lastUserMessage(user) {
      return this.args.thread.messagesManager.findLastUserMessage(user);
    }
    handleEscape(event) {
      if (this.draft.editing) {
        event.stopPropagation();
        this.args.thread.draft = _chatMessage.default.createDraftMessage(this.args.thread.channel, {
          user: this.currentUser,
          thread: this.args.thread
        });
        return;
      }
      if (this.isFocused) {
        event.stopPropagation();
        this.composer.blur();
      } else {
        this.pane.close().then(() => {
          this.channelComposer.focus();
        });
      }
    }
  }
  _exports.default = ChatComposerThread;
});