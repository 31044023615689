define("discourse/plugins/chat/discourse/services/chat-audio-manager", ["exports", "@ember/service", "discourse-common/config/environment", "discourse-common/lib/get-url", "discourse-common/utils/decorators"], function (_exports, _service, _environment, _getUrl, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHAT_SOUNDS = void 0;
  const AUDIO_DEBOUNCE_DELAY = 3000;
  const CHAT_SOUNDS = _exports.CHAT_SOUNDS = {
    bell: [{
      src: "/plugins/chat/audio/bell.mp3",
      type: "audio/mpeg"
    }],
    ding: [{
      src: "/plugins/chat/audio/ding.mp3",
      type: "audio/mpeg"
    }]
  };
  const DEFAULT_SOUND_NAME = "bell";
  const createAudioCache = sources => {
    const audio = new Audio();
    audio.pause();
    sources.forEach(_ref => {
      let {
        type,
        src
      } = _ref;
      const source = document.createElement("source");
      source.type = type;
      source.src = (0, _getUrl.getURLWithCDN)(src);
      audio.appendChild(source);
    });
    return audio;
  };
  class ChatAudioManager extends _service.default {
    _audioCache = {};
    setup() {
      Object.keys(CHAT_SOUNDS).forEach(soundName => {
        this._audioCache[soundName] = createAudioCache(CHAT_SOUNDS[soundName]);
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this._audioCache = {};
    }
    playImmediately(soundName) {
      return this._play(soundName);
    }
    play(soundName) {
      return this._play(soundName);
    }
    static #_ = dt7948.n(this.prototype, "play", [(0, _decorators.debounce)(AUDIO_DEBOUNCE_DELAY, true)]);
    _play(soundName) {
      const audio = this._audioCache[soundName] || this._audioCache[DEFAULT_SOUND_NAME];
      audio.muted = (0, _environment.isTesting)();
      if (!audio.paused) {
        audio.pause();
        if (typeof audio.fastSeek === "function") {
          audio.fastSeek(0);
        } else {
          audio.currentTime = 0;
        }
      }
      return audio.play().catch(() => {
        if (!(0, _environment.isTesting)()) {
          // eslint-disable-next-line no-console
          console.info("[chat] User needs to interact with DOM before we can play notification sounds.");
        }
      });
    }
  }
  _exports.default = ChatAudioManager;
});