define("discourse/plugins/chat/discourse/services/emoji-picker-scroll-observer", ["exports", "@glimmer/tracking", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _tracking, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmojiPickerScrollObserver extends _service.default {
    static #_ = dt7948.g(this.prototype, "chatEmojiPickerManager", [_service.service]);
    #chatEmojiPickerManager = (dt7948.i(this, "chatEmojiPickerManager"), void 0);
    static #_2 = dt7948.g(this.prototype, "enabled", [_tracking.tracked], function () {
      return true;
    });
    #enabled = (dt7948.i(this, "enabled"), void 0);
    direction = "up";
    prevYPosition = 0;
    _observerCallback(event) {
      if (!this.enabled) {
        return;
      }
      this._setScrollDirection(event.target);
      const visibleSections = [...document.querySelectorAll(".chat-emoji-picker__section")].filter(sectionElement => this._isSectionVisibleInPicker(sectionElement, event.target));
      if (visibleSections?.length) {
        let sectionElement;
        if (this.direction === "up" || this.prevYPosition < 50) {
          sectionElement = visibleSections.firstObject;
        } else {
          sectionElement = visibleSections.lastObject;
        }
        this.chatEmojiPickerManager.lastVisibleSection = sectionElement.dataset.section;
        this.chatEmojiPickerManager.addVisibleSections(visibleSections.map(s => s.dataset.section));
      }
    }
    static #_3 = dt7948.n(this.prototype, "_observerCallback", [_decorators.bind]);
    observe(element) {
      element.addEventListener("scroll", this._observerCallback);
    }
    unobserve(element) {
      element.removeEventListener("scroll", this._observerCallback);
    }
    _setScrollDirection(target) {
      if (target.scrollTop > this.prevYPosition) {
        this.direction = "down";
      } else {
        this.direction = "up";
      }
      this.prevYPosition = target.scrollTop;
    }
    _isSectionVisibleInPicker(section, picker) {
      const {
        bottom,
        height,
        top
      } = section.getBoundingClientRect();
      const containerRect = picker.getBoundingClientRect();
      return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }
  }
  _exports.default = EmojiPickerScrollObserver;
});