define("discourse/plugins/chat/discourse/services/chat-channel-notices-manager", ["exports", "@glimmer/tracking", "@ember/service", "@ember-compat/tracked-built-ins", "discourse/plugins/chat/discourse/models/chat-notice"], function (_exports, _tracking, _service, _trackedBuiltIns, _chatNotice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelNoticesManager extends _service.default {
    static #_ = dt7948.g(this.prototype, "notices", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedArray();
    });
    #notices = (dt7948.i(this, "notices"), void 0);
    handleNotice(data) {
      this.notices.pushObject(_chatNotice.default.create(data));
    }
    clearNotice(notice) {
      this.notices.removeObject(notice);
    }
  }
  _exports.default = ChatChannelNoticesManager;
});