define("discourse/plugins/chat/discourse/helpers/chat-guardian", ["exports", "@ember/component/helper", "@ember/service", "@ember/string"], function (_exports, _helper, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatGuardianHelper extends _helper.default {
    static #_ = dt7948.g(this.prototype, "chatGuardian", [_service.service]);
    #chatGuardian = (dt7948.i(this, "chatGuardian"), void 0);
    compute(inputs) {
      const [key, ...params] = inputs;
      if (!key) {
        return;
      }
      return this.chatGuardian[(0, _string.camelize)(key)]?.(...params);
    }
  }
  _exports.default = ChatGuardianHelper;
});