define("discourse/plugins/chat/discourse/components/chat/composer/channel", ["exports", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-composer"], function (_exports, _object, _service, _decorators, _discourseI18n, _chatComposer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatComposerChannel extends _chatComposer.default {
    static #_ = dt7948.g(this.prototype, "composer", [(0, _service.service)("chat-channel-composer")]);
    #composer = (dt7948.i(this, "composer"), void 0);
    static #_2 = dt7948.g(this.prototype, "pane", [(0, _service.service)("chat-channel-pane")]);
    #pane = (dt7948.i(this, "pane"), void 0);
    static #_3 = dt7948.g(this.prototype, "currentUser", [_service.service]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_4 = dt7948.g(this.prototype, "chatDraftsManager", [_service.service]);
    #chatDraftsManager = (dt7948.i(this, "chatDraftsManager"), void 0);
    context = "channel";
    composerId = "channel-composer";
    persistDraft() {
      this.chatDraftsManager.add(this.draft, this.args.channel.id);
    }
    static #_5 = dt7948.n(this.prototype, "persistDraft", [(0, _decorators.debounce)(2000)]);
    destroyDraft() {
      this.chatDraftsManager.remove(this.args.channel.id);
    }
    static #_6 = dt7948.n(this.prototype, "destroyDraft", [_object.action]);
    resetDraft() {
      this.args.channel.resetDraft(this.currentUser);
    }
    static #_7 = dt7948.n(this.prototype, "resetDraft", [_object.action]);
    get draft() {
      return this.args.channel.draft;
    }
    get presenceChannelName() {
      const channel = this.args.channel;
      return `/chat-reply/${channel.id}`;
    }
    get disabled() {
      return !this.chat.userCanInteractWithChat || !this.args.channel.canModifyMessages(this.currentUser);
    }
    get lastMessage() {
      return this.args.channel.lastMessage;
    }
    lastUserMessage(user) {
      return this.args.channel.messagesManager.findLastUserMessage(user);
    }
    get placeholder() {
      if (!this.args.channel.canModifyMessages(this.currentUser)) {
        return _discourseI18n.default.t(`chat.placeholder_new_message_disallowed.${this.args.channel.status}`);
      }
      if (!this.chat.userCanInteractWithChat) {
        return _discourseI18n.default.t("chat.placeholder_silenced");
      } else {
        return this.#messageRecipients(this.args.channel);
      }
    }
    handleEscape(event) {
      event.stopPropagation();
      if (this.draft?.inReplyTo) {
        this.draft.inReplyTo = null;
      } else if (this.draft?.editing) {
        this.args.channel.resetDraft(this.currentUser);
      } else {
        event.target.blur();
      }
    }
    #messageRecipients(channel) {
      if (channel.isDirectMessageChannel) {
        if (channel.chatable.group && channel.title) {
          return _discourseI18n.default.t("chat.placeholder_channel", {
            channelName: `#${channel.title}`
          });
        } else {
          const directMessageRecipients = channel.chatable.users;
          if (directMessageRecipients.length === 1 && directMessageRecipients[0].id === this.currentUser.id) {
            return _discourseI18n.default.t("chat.placeholder_self");
          }
          return _discourseI18n.default.t("chat.placeholder_users", {
            commaSeparatedNames: directMessageRecipients.map(u => u.name || `@${u.username}`).join(_discourseI18n.default.t("word_connector.comma"))
          });
        }
      } else {
        return _discourseI18n.default.t("chat.placeholder_channel", {
          channelName: `#${channel.title}`
        });
      }
    }
  }
  _exports.default = ChatComposerChannel;
});