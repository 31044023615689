define("discourse/plugins/chat/discourse/components/styleguide/chat-modal-delete-channel", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/components/chat/modal/delete-channel", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _application, _object, _service, _deleteChannel, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<Chat::Modal::DeleteChannel>">
    <Styleguide::Controls::Row>
      <DButton @translatedLabel="Open modal" @action={{this.openModal}} />
    </Styleguide::Controls::Row>
  </StyleguideExample>
  */
  {
    "id": "RePA6/AH",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<Chat::Modal::DeleteChannel>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@translatedLabel\",\"@action\"],[\"Open modal\",[30,0,[\"openModal\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/controls/row\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-modal-delete-channel.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatModalDeleteChannel extends _component2.default {
    static #_ = dt7948.g(this.prototype, "modal", [_service.service]);
    #modal = (dt7948.i(this, "modal"), void 0);
    channel = new _fabricators.default((0, _application.getOwner)(this)).channel();
    openModal() {
      return this.modal.show(_deleteChannel.default, {
        model: {
          channel: this.channel
        }
      });
    }
    static #_2 = dt7948.n(this.prototype, "openModal", [_object.action]);
  }
  _exports.default = ChatStyleguideChatModalDeleteChannel;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatModalDeleteChannel);
});