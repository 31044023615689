define("discourse/plugins/chat/discourse/services/chat-drawer-router", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channels", "discourse/plugins/chat/discourse/components/chat/drawer-routes/threads"], function (_exports, _tracking, _service, _channel, _channelThread, _channelThreads, _channels, _threads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROUTES = {
    "chat.channel": {
      name: _channel.default
    },
    "chat.channel.thread": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          threadId: route.params.threadId
        };
      }
    },
    "chat.channel.thread.index": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          threadId: route.params.threadId
        };
      }
    },
    "chat.channel.thread.near-message": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.parent.params.channelId,
          threadId: route.parent.params.threadId,
          messageId: route.params.messageId
        };
      }
    },
    "chat.channel.threads": {
      name: _channelThreads.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId
        };
      }
    },
    "chat.threads": {
      name: _threads.default
    },
    chat: {
      name: _channels.default
    },
    "chat.channel.near-message": {
      name: _channel.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          messageId: route.params.messageId
        };
      }
    },
    "chat.channel-legacy": {
      name: _channel.default,
      extractParams: route => {
        return {
          channelId: route.params.channelId,
          messageId: route.queryParams.messageId
        };
      }
    }
  };
  class ChatDrawerRouter extends _service.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatHistory", [_service.service]);
    #chatHistory = (dt7948.i(this, "chatHistory"), void 0);
    static #_3 = dt7948.g(this.prototype, "component", [_tracking.tracked], function () {
      return null;
    });
    #component = (dt7948.i(this, "component"), void 0);
    static #_4 = dt7948.g(this.prototype, "drawerRoute", [_tracking.tracked], function () {
      return null;
    });
    #drawerRoute = (dt7948.i(this, "drawerRoute"), void 0);
    static #_5 = dt7948.g(this.prototype, "params", [_tracking.tracked], function () {
      return null;
    });
    #params = (dt7948.i(this, "params"), void 0);
    stateFor(route) {
      this.drawerRoute?.deactivate?.(this.chatHistory.currentRoute);
      this.chatHistory.visit(route);
      this.drawerRoute = ROUTES[route.name];
      this.params = this.drawerRoute?.extractParams?.(route) || route.params;
      this.component = this.drawerRoute?.name || _channels.default;
      this.drawerRoute.activate?.(route);
    }
  }
  _exports.default = ChatDrawerRouter;
});