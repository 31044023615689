define("discourse/plugins/chat/discourse/components/styleguide/chat-composer", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory"], function (_exports, _component, _component2, _application, _object, _service, _fabricators, _chatChannel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<ChatComposer>">
    <Styleguide::Component>
      <Chat::Composer::Channel
        @channel={{this.channel}}
        @onSendMessage={{this.onSendMessage}}
      />
    </Styleguide::Component>
  
    <Styleguide::Controls>
      <Styleguide::Controls::Row @name="Disabled">
        <DToggleSwitch
          @state={{this.channel.isReadOnly}}
          {{on "click" this.toggleDisabled}}
        />
      </Styleguide::Controls::Row>
      <Styleguide::Controls::Row @name="Sending">
        <DToggleSwitch
          @state={{this.chatChannelPane.sending}}
          {{on "click" this.toggleSending}}
        />
      </Styleguide::Controls::Row>
    </Styleguide::Controls>
  </StyleguideExample>
  */
  {
    "id": "44rPT0VR",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<ChatComposer>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@channel\",\"@onSendMessage\"],[[30,0,[\"channel\"]],[30,0,[\"onSendMessage\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Disabled\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[4,[38,6],[\"click\",[30,0,[\"toggleDisabled\"]]],null]],[[\"@state\"],[[30,0,[\"channel\",\"isReadOnly\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Sending\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[4,[38,6],[\"click\",[30,0,[\"toggleSending\"]]],null]],[[\"@state\"],[[30,0,[\"chatChannelPane\",\"sending\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"chat/composer/channel\",\"styleguide/controls\",\"styleguide/controls/row\",\"d-toggle-switch\",\"on\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-composer.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatComposer extends _component2.default {
    static #_ = dt7948.g(this.prototype, "chatChannelComposer", [_service.service]);
    #chatChannelComposer = (dt7948.i(this, "chatChannelComposer"), void 0);
    static #_2 = dt7948.g(this.prototype, "chatChannelPane", [_service.service]);
    #chatChannelPane = (dt7948.i(this, "chatChannelPane"), void 0);
    channel = new _fabricators.default((0, _application.getOwner)(this)).channel({
      id: -999
    });
    toggleDisabled() {
      if (this.channel.status === _chatChannel.CHANNEL_STATUSES.open) {
        this.channel.status = _chatChannel.CHANNEL_STATUSES.readOnly;
      } else {
        this.channel.status = _chatChannel.CHANNEL_STATUSES.open;
      }
    }
    static #_3 = dt7948.n(this.prototype, "toggleDisabled", [_object.action]);
    toggleSending() {
      this.chatChannelPane.sending = !this.chatChannelPane.sending;
    }
    static #_4 = dt7948.n(this.prototype, "toggleSending", [_object.action]);
    onSendMessage() {
      this.chatChannelComposer.reset();
    }
    static #_5 = dt7948.n(this.prototype, "onSendMessage", [_object.action]);
  }
  _exports.default = ChatStyleguideChatComposer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatComposer);
});