define("discourse/plugins/chat/discourse/components/chat/navbar/thread-settings-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _threadSettings, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarThreadSettingsButton extends _component.default {
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.service]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_2 = dt7948.g(this.prototype, "modal", [_service.service]);
    #modal = (dt7948.i(this, "modal"), void 0);
    get canChangeThreadSettings() {
      if (!this.args.thread) {
        return false;
      }
      return this.currentUser.staff || this.currentUser.id === this.args.thread.originalMessage.user.id;
    }
    openThreadSettings() {
      this.modal.show(_threadSettings.default, {
        model: this.args.thread
      });
    }
    static #_3 = dt7948.n(this.prototype, "openThreadSettings", [_object.action]);
    static #_4 = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.canChangeThreadSettings}}
          <DButton
            @action={{this.openThreadSettings}}
            @icon="cog"
            @title="chat.thread.settings"
            class="btn-transparent c-navbar__thread-settings-button"
          />
        {{/if}}
      
    */
    {
      "id": "5UZKqgno",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"canChangeThreadSettings\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-transparent c-navbar__thread-settings-button\"]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"openThreadSettings\"]],\"cog\",\"chat.thread.settings\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/thread-settings-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this);
  }
  _exports.default = ChatNavbarThreadSettingsButton;
});