define("discourse/plugins/chat/discourse/routes/chat-channel-legacy", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelLegacyRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    redirect() {
      const {
        channelTitle,
        channelId,
        messageId
      } = this.paramsFor(this.routeName);
      this.router.replaceWith("chat.channel", channelTitle, channelId, {
        queryParams: {
          messageId
        }
      });
    }
  }
  _exports.default = ChatChannelLegacyRoute;
});