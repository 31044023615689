define("discourse/plugins/chat/discourse/services/chat-thread-list-pane", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadListPane extends _service.default {
    static #_ = dt7948.g(this.prototype, "chat", [_service.service]);
    #chat = (dt7948.i(this, "chat"), void 0);
    static #_2 = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    get isOpened() {
      return this.router.currentRoute.name === "chat.channel.threads";
    }
    async close() {
      await this.router.transitionTo("chat.channel", ...this.chat.activeChannel.routeModels);
    }
    async open() {
      await this.router.transitionTo("chat.channel.threads", ...this.chat.activeChannel.routeModels);
    }
  }
  _exports.default = ChatThreadListPane;
});