define("discourse/plugins/chat/discourse/components/styleguide/chat-thread-list-item", ["exports", "@ember/component", "@glimmer/component", "@ember/application", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _application, _service, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<Chat::ThreadList::Item>">
    <Styleguide::Component>
      <Chat::ThreadList::Item @thread={{this.thread}} />
    </Styleguide::Component>
  </StyleguideExample>
  */
  {
    "id": "lR91t83a",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<Chat::ThreadList::Item>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@thread\"],[[30,0,[\"thread\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"chat/thread-list/item\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-thread-list-item.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatThreadListItem extends _component2.default {
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.service]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    thread = new _fabricators.default((0, _application.getOwner)(this)).thread();
  }
  _exports.default = ChatStyleguideChatThreadListItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatThreadListItem);
});