define("discourse/plugins/chat/discourse/routes/chat-channel-info-members", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelInfoMembersRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    afterModel(model) {
      if (!model.isOpen) {
        return this.router.replaceWith("chat.channel.info.settings");
      }
    }
  }
  _exports.default = ChatChannelInfoMembersRoute;
});